<template>
    <div class="service">
       <div class="service-nav" @click="goBack"></div>
        <div class="iframe-box">
            <iframe src="https://tb.53kf.com/code/client/2214fc723ccc1bc345ceaecd74b06d747/1" width="100%" height="100%" frameborder="0"></iframe>
        </div>
    </div>
</template>
<script>
import { useRouter } from 'vue-router';
import {onMounted} from 'vue';
export default {
    setup() {
        let router = useRouter();
        
        onMounted(() => {
            document.body.scrollTop = 1;
            document.documentElement.scrollTop = 1;
            // 兼容IE
            window.scrollTo(0,1);
        })
        const goBack = () =>{
           router.go(-1);
        }
        return {
            goBack
        }
    },

}
</script>
<style lang="less" scoped>
.service {
    height: calc(100vh + 1px);
    // background-color: #0094ff;
    .service-nav {
        height: 12vw;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
    .iframe-box {
        position: relative;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
}
</style>